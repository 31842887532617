<template>
  <div class="calculators-list page-layout">
    <PageHeader
      :has-back="true"
      @back="goBack"
      title="Calculatrices"/>
    <!--impot revenu-->

    <md-card
      class="md-layout-item md-size-75 md-small-size-85">
      <md-list>
        <md-list-item
          to="/admin/calculators/impot-revenu/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              attach_money
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Impôt Revenu
          </div>
        </md-list-item>
      </md-list>

      <!--impot societe-->

      <md-list>
        <md-list-item
          to="/admin/calculators/impot-societes/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              supervisor_account
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Impôt Societe
          </div>
        </md-list-item>
      </md-list>

      <!--indemnite kilometriques-->

      <md-list>
        <md-list-item
          to="/admin/calculators/indemnite-kilometrique/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              near_me
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Indemnite Kilometrique
          </div>
        </md-list-item>
      </md-list>


      <!--salaire-->

      <md-list>
        <md-list-item
          to="/admin/calculators/salaire/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              euro_symbol
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Salaire
          </div>
        </md-list-item>
      </md-list>

      <!--Tax de societe carburant-->

      <md-list>
        <md-list-item
          to="/admin/calculators/taxe-vehicule-societe-carburant/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              local_gas_station
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Taxe sur vehicule société (carburant)
          </div>
        </md-list-item>
      </md-list>

      <!--Tax Vehicule Societe Emission-->

      <md-list>
        <md-list-item
          to="/admin/calculators/taxe-vehicule-societe-emission/list">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              drive_eta
            </md-icon>
          </md-avatar>
          <div
            class="md-list-item-text">
            Taxe sur vehicule société (émissions de CO₂)
          </div>
        </md-list-item>
      </md-list>
    </md-card>
  </div>
</template>


<script>
    import PageHeader from "@/components/PageHeader";

    export default {
        components : { PageHeader },
        data() {
            return {
                isDebug        : false,
                query          : "",
                data           : [],
                listParameters : [
                    {
                        name : "Date de création",
                        key  : "created_at",
                    },
                    {
                        name   : "Mise a Jour",
                        key    : "n",
                        format : this.$$filters.formatTimestamp,
                    },
                ],
            };
        },
        methods    : {
            goBack() {
                window.history.back();
            },
        },
        computed   : {
            filter() {
                if (this.query === "") {
                    return this.data;
                } else {
                    let q = this.query.toLowerCase();
                    return this.data.filter(item => {
                        return (item.name.toLowerCase().indexOf(q) > -1);
                    });
                }
            },
        },
    };
</script>

<style lang="scss">
  .calculators-list {
    justify-content: space-around;

    .md-card {
       margin: 40px auto 30px;
    }
  }
</style>
